.cs-text-center {
  text-align: center;
}

.cs-p-5 {
  padding: 40px;
}

.cs-mt-5 {
  margin-top: 40px;
}

.cs-mr-2 {
  margin-right: 0.5em;
}

.cs-mb-5 {
  margin-bottom: 40px;
}